// import logo from './logo.svg';
// import './App.css';

// import GEO from './geo';
import React, { useState, useEffect } from 'react'
import Geocode from "react-geocode";
import xtype from 'xtypejs'
import axios from "axios";

// import CurrentLocation from 'react-current-location-address'

function App() {

  Geocode.setApiKey("AIzaSyA0FRlNQX7UsH6Zp95g8T1gOAhTryUGuZI");
  Geocode.setLanguage("en");

  // set response region. Its optional.
  // A Geocoding request with region=es (Spain) will return the Spanish city.
  Geocode.setRegion("es");
  
  // set location_type filter . Its optional.
  // google geocoder returns more that one address for given lat/lng.
  // In some case we need one address as response for which google itself provides a location_type filter.
  // So we can easily parse the result for fetching address components
  // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
  // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
  Geocode.setLocationType("ROOFTOP");
  
  // Enable or disable logs. Its optional.
  Geocode.enableDebug();




  const [lat,setLat]=useState()
  const [lon,setLon]=useState()
  const [addr,setAddr]=useState()

  useEffect(()=>{
  navigator.geolocation.getCurrentPosition((postion)=>{
    setLat(postion.coords.latitude)
    setLon(postion.coords.longitude)
})
})
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';
  const getlocation = () => {
    
    // navigator.geolocation.getCurrentPosition((postion)=>{
    //   setLat(postion.coords.latitude)
    //   setLon(postion.coords.longitude)

      // Geocode.fromLatLng("14.7914752", "121.0318848").then(
      //   (response) => {
      //     // console.log(lat, lon)
      //      const address = response.results[0].formatted_address;
      //     // // const address = response.results
      //     console.log(address);
      //     // setAddr(address)
      //     // console.log(xtype, lat)
      //   },
      //   (error) => {
      //     console.error(error);
      //   }
      // );

    //   Geocode.fromLatLng("48.8583701", "2.2922926").then(
    //     (response) => {
    //       const address = response.results[0].formatted_address;
    //       console.log(lat);
    //     },
    //     (error) => {
    //       console.error(error);
    //     }
    //   );
      
  // })

  const extractAddress = (place) => {

    const address = {
      city: "",
      state: "",
      zip: "",
      country: "",
      plain() {
        const city = this.city ? this.city + ", " : "";
        const zip = this.zip ? this.zip + ", " : "";
        const state = this.state ? this.state + ", " : "";
        return city + zip + state + this.country;
      }
    }
  
    if (!Array.isArray(place?.address_components)) {
      return address;
    }
  
    place.address_components.forEach(component => {
      const types = component.types;
      const value = component.long_name;
  
      if (types.includes("locality")) {
        address.city = value;
      }
  
      if (types.includes("administrative_area_level_2")) {
        address.state = value;
      }
  
      if (types.includes("postal_code")) {
        address.zip = value;
      }
  
      if (types.includes("country")) {
        address.country = value;
      }
  
    });
  
    return address;
  }
  
  const KEY = "AIzaSyA0FRlNQX7UsH6Zp95g8T1gOAhTryUGuZI";

  const url = `${geocodeJson}?key=${KEY}&latlng=${lat},${lon}`;
  // console.log(lat)

  fetch(url)
      .then(response => response.json())
      // console.log(response)
      .then(location => {
        console.log(location)
        const place = location.results[0];
        const _address = extractAddress(place);
        // setAddress(addre);

        console.log(place)
        setAddr(place.formatted_address);
  
       // searchInput.current.value = _address.plain();
      })


  // fetch(url)
  //   .then(response => response.json())
  //   .then(data => {
  //     console.log(data);
  //     let parts = data.results[0].address_components;
  //     document.body.insertAdjacentHTML(
  //       "beforeend",
  //       `<p>Formatted: ${data.results[0].formatted_address}</p>`
  //     );
      // parts.forEach(part => {
      //   if (part.types.includes("country")) {
      //     //we found "country" inside the data.results[0].address_components[x].types array
      //     document.body.insertAdjacentHTML(
      //       "beforeend",
      //       `<p>COUNTRY: ${part.long_name}</p>`
      //     );
      //   }
      //   if (part.types.includes("administrative_area_level_1")) {
      //     document.body.insertAdjacentHTML(
      //       "beforeend",
      //       `<p>PROVINCE: ${part.long_name}</p>`
      //     );
      //   }
      //   if (part.types.includes("administrative_area_level_3")) {
      //     document.body.insertAdjacentHTML(
      //       "beforeend",
      //       `<p>LEVEL 3: ${part.long_name}</p>`
      //     );
      //   }
      // });
  

  }
  // function sayHello() {
  //   alert('You clicked me!');
  // }
  return (
 
    <div className="App">
<button  onClick={getlocation}>Default</button>
<h1>{lat} location {lon}</h1>
<h2>Address: {addr}</h2>
    </div>

      
 
  )
}

export default App;
